/* eslint-disable */
import React from "react"
import {graphql} from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import BlueHeader from '../../components/common/BlueHeader'
import Container from '../../components/common/Container'
import Offers from '../../components/common/Offers'

class ReviewsPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      bvid: null,
      title: 'Loading...',
      query: null,
      image: null
    }
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const bvid = urlParams.get('bvid')
    const title = urlParams.get('title')
    this.setState({
      bvid,
      title,
      query: queryString
    })
    switch (bvid){
      case 'vupr_master_en':
      case 'vupr_master_fr':
        this.setState({
          image: 'https://prodweb.royaleapi.irvingconsumerproducts.com//globalassets/up_new.png'
        })
        break;
      case 'VelourBathroomTissue-EN':
      case'VelourBathroomTissue-FR':
        this.setState({
          image: 'https://prodweb.royaleapi.irvingconsumerproducts.com//globalassets/products/bathroom-tissue/velour/velour.png'
        })
        break;
      case 'OriginalBathroomTissue-EN':
      case'OriginalBathroomTissue-FR':
        this.setState({
          image: 'https://prodweb.royaleapi.irvingconsumerproducts.com//contentassets/93a2ca992800417ba395ec6886b9daa6/19_1014_198_010_o_royale_original.png'
        })
        break;
      case '3PlyBathroomTissue-EN':
      case'3PlyBathroomTissue-FR':
        this.setState({
          image: 'https://prodweb.royaleapi.irvingconsumerproducts.com//globalassets/products/bathroom-tissue/3-ply/3ply-new-logo-tmp.png'
        })
        break;
      case 'new_rtt_1r_123_master_en':
      case'new_rtt_1r_123_master_fr':
        this.setState({
          image: 'https://prodweb.royaleapi.irvingconsumerproducts.com//globalassets/products/tiger-towel/royale_tigertowel_logo-tmp.png'
        })
        break;
    }
  }


  render() {
    const { data } = this.props
    const { bvid, title, query, image } = this.state
    let urls = {
      en: data.defaultPage.urls.en + query,
      fr: data.defaultPage.urls.fr + query
    }
    return (
      <Layout
        meta={data.defaultPage.meta}
        urls={urls}
        locale='fr'
      >
        <Header
          urls={urls}
          locale='fr'
        />

        <BlueHeader image={image} size={1260} title={title} />
        <Container width={1260}>
          <div style={{padding: '75px 0 100px'}}>
            {
              bvid != null && title.length != null ? (
                  <div>
                    <div className="review-highlights" id="bv-reviews-containter">
                      <div id="bv-reviews" data-bv-show="reviews" data-bv-product-id={bvid} />
                    </div>
                  </div>
                )
                : (
                  <div>
                    <h2 className="ptitle">Téléchargement</h2>
                    <p className="normal">Nous sommes en train de télécharger les avis, un instant.</p>
                  </div>
                )}
          </div>
        </Container>
        <Footer />
        <Offers />
      </Layout>
    )
  }
}

export const query = graphql`
query {
  defaultPage(guid: { eq: "2c02381f-a9ee-4a41-b00e-bb08fbc8895a" }, locale: {eq:"fr"}) {
    title
    subtitle
    description
    blocks {
      __typename
      ...textBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default ReviewsPage
